import React from 'react';
import SEO from 'components/SEO';
import Hero from 'components/AnimationHero';
import { graphql } from 'gatsby';

const Animation = ({ data }) => {
  return (
    <>
      <SEO
        title={data.contentfulPageVideo.metaTitle}
        description={data.contentfulPageVideo.metaDescription}
        image={'https:' + data.contentfulPageVideo.metaImage.file.url}
        pathname={'video'}
      />
      <Hero
        video={data.contentfulPageVideo.video}
        poster={data.contentfulPageVideo.videoPoster.file.url}
      />
    </>
  );
};

export default Animation;

export const pageQuery = graphql`
  query VideoPageQuery {
    contentfulPageVideo {
      metaTitle
      metaDescription
      metaImage {
        file {
          url
        }
      }
      video {
        file {
          url
          contentType
        }
      }
      videoPoster {
        file {
          url
        }
      }
    }
  }
`;
