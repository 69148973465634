import React from 'react';
import gsap from 'gsap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { colors } from 'styles/vars/colors.style';
import { Controls, PlayPauseButton, Video, Wrapper } from './index.style';

const AnimationHero = ({ video, poster }) => {
  const wrapperRef = useRef(null);
  const playButtonRef = useRef(null);
  const controlsRef = useRef(null);
  const mouseMoveTimeout = useRef();
  const mouseInVideo = useRef(false);
  const firstPlay = useRef(true);

  const [videoEl, setVideoEl] = useState(null);
  const [paused, setPaused] = useState(true);
  const [muted, setMuted] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [svgColor, setSvgColor] = useState(colors.humeTan400);

  const handleOrientation = useCallback((e) => {
    setTimeout(() => {
      gsap.set(wrapperRef.current, {
        overwrite: true,
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 250);
  }, []);

  const handlePlay = () => {
    setPaused(false);
    setStartTime(Date.now());
    window?.posthog.capture('animation_hero:video_start', {
      videoStartTime: startTime,
    });
  };

  const handlePause = () => {
    setPaused(true);
    const watchDuration = Date.now() - startTime;
    window?.posthog.capture('animation_hero:video_stop', {
      watch_duration: watchDuration,
    });
    setStartTime(null);
  };

  const mouseMove = useCallback(() => {
    if (mouseInVideo.current) {
      clearTimeout(mouseMoveTimeout.current);

      gsap.to([controlsRef.current, playButtonRef.current], {
        opacity: 1,
      });

      mouseMoveTimeout.current = setTimeout(() => {
        gsap.to([controlsRef.current, playButtonRef.current], {
          opacity: 0,
          duration: 0.75,
        });
      }, 250);
    }
  }, []);

  const mouseEnter = () => {
    mouseInVideo.current = true;
  };

  const mouseLeave = () => {
    mouseInVideo.current = false;
  };

  const moveButtonDown = () => {
    gsap.to(playButtonRef.current, {
      bottom: 50,
      background: colors.neutralWhite,
      width: 75,
      height: 75,
    });

    setSvgColor(colors.humeBlack700);
  };

  useEffect(() => {
    if (videoEl) {
      videoEl.addEventListener('play', handlePlay);
      videoEl.addEventListener('pause', handlePause);
      if (paused) {
        videoEl.pause();
      } else {
        videoEl.play();
        if (firstPlay.current) {
          moveButtonDown();
          firstPlay.current = false;
        }
      }
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener('play', handlePlay);
        videoEl.removeEventListener('pause', handlePause);
      }
    };
  }, [paused, videoEl, handlePlay, handlePause]);

  useEffect(() => {
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('touchstart', mouseMove);
    window.addEventListener('orientationchange', handleOrientation);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchstart', mouseMove);
      window.removeEventListener('orientationchange', handleOrientation);
    };
  }, [mouseMove, handleOrientation]);

  return (
    <Wrapper
      ref={wrapperRef}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <Video
        ref={(ref) => setVideoEl(ref)}
        muted={muted}
        playsInline
        poster={poster}
      >
        <source src={video.file.url} type={video.file.contentType} />
      </Video>
      <PlayPauseButton
        ref={playButtonRef}
        onClick={() => {
          if (paused) {
            handlePlay();
          } else {
            handlePause();
          }
        }}
      >
        {paused && (
          <svg
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="video-fill"
              d="M7.61523 20.5469L21.6152 11.5469L7.61523 2.54688V20.5469Z"
              fill={svgColor}
            />
          </svg>
        )}
        {!paused && (
          <svg
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              className="video-fill"
              x="4.61523"
              y="1.5"
              width="3"
              height="16"
              fill={svgColor}
            />
            <rect
              className="video-fill"
              x="11.6152"
              y="1.5"
              width="3"
              height="16"
              fill={svgColor}
            />
          </svg>
        )}
      </PlayPauseButton>
      <Controls ref={controlsRef}></Controls>
    </Wrapper>
  );
};

export default AnimationHero;
