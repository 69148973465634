import styled from 'styled-components';
import { colors } from 'styles/vars/colors.style.js';
import { mq } from 'styles/vars/media-queries.style.js';
import { ReactComponent as UnmuteSVG } from 'images/unmute.svg';
import { ReactComponent as MuteSVG } from 'images/mute.svg';
import { ReactComponent as XSVG } from 'images/x.svg';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  border: 1px solid ${colors.humeBlack700};
  border-radius: 30px;
  overflow: hidden;
  background-color: ${colors.neutralWhite};

  width: 100vw;

  ${mq.mobile} {
    height: calc(100vh - 150px);
  }

  ${mq.mobileL} {
    height: calc(100vh - 100px);
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-size: cover;
`;

export const PlayPauseButton = styled.button`
  position: absolute;
  z-index: 2;
  bottom: 45%;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.humeBlack700};
  border: 1px solid ${colors.humeBlack700};
  cursor: pointer;

  svg {
    height: auto;
    width: 21px;
  }
`;

export const Unmute = styled(UnmuteSVG)`
  cursor: pointer;
  width: 16.5px;
  height: auto;
`;

export const Mute = styled(MuteSVG)`
  cursor: pointer;
  height: auto;
  width: 16.5px;
`;

export const Pill = styled.div`
  position: absolute;
  background-color: ${colors.humeBlack700};
  width: 105px;
  height: 42px;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 42px;
  opacity: 0;

  ${Mute} {
    path {
      fill: ${colors.humeTan400};
      stroke: ${colors.humeTan400};
    }
  }

  ${Unmute} {
    path {
      stroke: ${colors.humeTan400};
    }
  }
`;

export const VR = styled.div`
  width: 1px;
  height: 31px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: ${colors.humeTan400};
`;

export const Controls = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 120px;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: fit-content;
`;

export const ControlButton = styled.button`
  width: 56px;
  height: 56px;
  background: ${colors.neutralWhite};
  border: 1px solid ${colors.humeBlack700};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  svg {
    path,
    rect {
      stroke: ${colors.humeBlack700};
    }
  }

  ${Unmute}, ${Mute} {
    width: 22px;
    height: auto;

    path:first-of-type {
      fill: ${colors.humeBlack700};
    }
  }

  &:hover {
    background: ${colors.humeBlack700};

    ${Unmute}, ${Mute} {
      path:first-of-type {
        fill: ${colors.humeTan400};
      }
    }

    svg {
      path,
      rect {
        stroke: ${colors.humeTan400};
      }
    }
  }
`;

export const CloseButton = styled.button`
  border: 1px solid ${colors.humeBlack700};
  border-radius: 56px;
  background: ${colors.neutralWhite};
  position: absolute;
  z-index: 2;
  top: -50%;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  cursor: pointer;
  padding: 0px 15px;
  gap: 15px;

  ${mq.mobileL} {
    right: 5%;
  }

  &:hover {
    background: ${colors.humeBlack700};
    color: ${colors.humeTan400};

    ${Unmute}, ${Mute} {
      path:first-of-type {
        fill: ${colors.humeTan400};
      }
    }

    svg {
      path,
      rect {
        stroke: ${colors.humeTan400};
      }
    }
  }
`;

export const X = styled(XSVG)`
  height: auto;
  width: 14px;
`;
